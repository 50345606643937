import React, {useEffect, useState} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import {Geolocation} from '@capacitor/geolocation';
import './Globe.scss';
import {
  Viewer,
  CameraFlyTo,
  Globe,
  Clock,
  ScreenSpaceCameraController,
} from "resium";
import {
  Cartesian3,
  ClockRange,
  JulianDate,
  SceneMode,
  TimeInterval,
  TimeIntervalCollection,
} from "cesium";
import {CustomImageryLayer, GetImageryProvider} from "../../layers/LayerFactory";
import MainHeader from "../../components/MainHeader";
import Spinner from "../../components/Spinner";
import {connect} from 'react-redux'
import {setBaseLayer, setDataLayer, setOverlayLayer} from '../../redux/reducer';
import configuration, {cartographicLimitRectangle} from "../../config/config";
import getSources from '../../layers/sources';
import {BaseLayerDefinitions, DataLayerDefinitions, OverlayLayerDefinitions} from "../../layers/Layers";
import {useTranslation} from 'react-i18next';
import merge from 'lodash.merge';

const now = JulianDate.now();
type obj = Record<string, any>

interface GlobeProps {
  baseLayer: string,
  dataLayer: string,
  overlayLayer: string,
}

const mapStateToProps = (state: GlobeProps) => ({
  baseLayer: state.baseLayer,
  dataLayer: state.dataLayer,
  overlayLayer: state.overlayLayer,
})

const mapDispatchToProps = { setBaseLayer, setDataLayer, setOverlayLayer }

const HomeAnimation: React.FC<{animating:boolean, longitude:number, latitude:number, stop:any}> = ({animating, longitude, latitude, stop}) => {
  return animating?(
    <CameraFlyTo
      duration={2}
      destination={Cartesian3.fromDegrees(longitude, latitude, configuration.initial.altitude)}
      onComplete={stop}
      once={true}
    />
  ):null;
}

const GlobeWidget: React.FC<{baseLayer:string, dataLayer:string, overlayLayer: string, setBaseLayer:any, setDataLayer:any, setOverlayLayer:any }>
    = ({baseLayer, dataLayer, overlayLayer, setBaseLayer, setDataLayer, setOverlayLayer}) => {
  const { t } = useTranslation();
  const [locationState, setLocationState] = useState({
    done:false,
    location:{
      coords: {
        longitude: configuration.initial.longitude,
        latitude: configuration.initial.latitude,
    }}
  });

  const [baseLayerProvider, setBaseLayerProvider] = useState<any>(undefined);
  const [animating, setAnimating] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [baseLayerDefinitions, setBaseLayerDefinitions ] = useState<any>(undefined);
  const [dataLayerDefinitions, setDataLayerDefinitions ] = useState<any>(undefined);
  const [overlayLayerDefinitions, setOverlayLayerDefinitions ] = useState<any>(undefined);
  const [selectedDataLayerDefinition, setSelectedDataLayerDefinition ] = useState<any>(undefined);
  const [selectedOverlayLayerDefinition, setSelectedOverlayLayerDefinition ] = useState<any>(undefined);
  const [sources, setSources ] = useState<any>(undefined);
  const [clockProps, setClockProps] = useState<obj|undefined>(undefined);

  let viewer:any;
  let clock: any;

  const changeDataLayerEffect = () => {
    if(dataLayer) {
      const definition = merge({}, dataLayerDefinitions?.[dataLayer]);
      const type = definition.type;
      const source = definition.source;
      const id = definition.providerOptions.layers || definition.providerOptions.layer;

      const capability = sources[type]?.[source]?.layers?.[id];

      const dimension = capability?.dimension;

      if(clock && Array.isArray(dimension)) {
        let times;
        try {
          times = TimeIntervalCollection.fromIso8601({
            iso8601: dimension[dimension.length-1],
            leadingInterval: false,
            trailingInterval: false,
            isStartIncluded: true,
            isStopIncluded: true,
            dataCallback: dataCallback,
          });

          const start = times.start;
          const stop = times.stop;

          const contains = TimeInterval.contains(new TimeInterval({start, stop}), now);
          const later = JulianDate.greaterThan(now,  stop);
          const current = contains?now:later?stop:start;
          const duration = JulianDate.secondsDifference(stop, start)/times.length;
          const delay = 10;
          const multiplier = Math.floor(duration/delay);
          setClockProps({
            startTime: start,
            currentTime: current,
            stopTime: stop,
            multiplier:multiplier
          })
          definition.providerOptions.times = times;
          definition.providerOptions.clock = clock;
        } catch (e) {
          console.trace(e);
        }
      } else {
        setClockProps(undefined)
      }

      setSelectedDataLayerDefinition(definition);
    } else {
      setSelectedDataLayerDefinition(undefined);
      setClockProps(undefined);
    }
  }

  const changeOverlayLayerEffect = () => {
    if(overlayLayer) {
      const definition = merge({}, overlayLayerDefinitions?.[overlayLayer]);
      setSelectedOverlayLayerDefinition(definition);
    } else {
      setSelectedOverlayLayerDefinition(undefined);
    }
  }

  const updateBaseLayerProvider = () => {
    const definitions = baseLayerDefinitions[baseLayer];
    const newProvider = GetImageryProvider(definitions);
    setBaseLayerProvider(newProvider);
  }

  useEffect(() => {
    if(baseLayer && baseLayerDefinitions?.[baseLayer]) {
      updateBaseLayerProvider();
    }
  }, [baseLayer, baseLayerDefinitions]);
  useEffect(changeDataLayerEffect, [dataLayer]);
  useEffect(changeOverlayLayerEffect, [overlayLayer]);

  useEffect(() => {
    if(configuration.features.askLocation) {
      Geolocation.getCurrentPosition()
          .then(results => {
            setLocationState({done:true, location:results});
          })
    } else {
      setLocationState({done: true, location: locationState.location});
    }

    getSources()
    .then(sources => {
      setSources(sources);
      setDataLayerDefinitions(DataLayerDefinitions(sources));
      setBaseLayerDefinitions(BaseLayerDefinitions(sources));
      setOverlayLayerDefinitions(OverlayLayerDefinitions(sources));
    });
  }, []);

  useEffect(() => {
    if(clockProps && viewer.timeline) {
      viewer.timeline.zoomTo(clockProps.startTime, clockProps.stopTime);
    }
  }, [clockProps]);

  const { longitude, latitude } = locationState.location.coords;

  const dataCallback = (interval:TimeInterval) => ({Time:JulianDate.toIso8601(interval.start)});

  const stopHomeAnimation = () => setAnimating(false);
  const startHomeAnimation = () => setAnimating(true);
  const tileLoadHandler:any = (progress:number) => {
    if(downloading && !!!progress) {
      setDownloading(false);
    } else if(!downloading && !!progress) {
      setDownloading(true);
    }
  }

  /*
   * NOTE: App hangs if layer changes while timeline is animating
   */
  const changeDataLayer = (layer:string) => {
    //stop animation
    clock.shouldAnimate = false;
    setDataLayer(layer);
  }

  const changeOverlayLayer = (layer:string) => {
    //stop animation
    clock.shouldAnimate = false;
    setOverlayLayer(layer);
  }

  return (
    <IonPage
      id="root-page"
    >
      <MainHeader
        id="main-header"
        title="Globe"
        baseLayer={baseLayer}
        dataLayer={dataLayer}
        overlayLayer={overlayLayer}
        setBaseLayer={setBaseLayer}
        setDataLayer={changeDataLayer}
        setOverlayLayer={changeOverlayLayer}
        goHome={startHomeAnimation}
        loading={downloading}
      />
      <IonContent
        id="main-content"
      >
        {
          baseLayerProvider?(
            <Viewer
              id={`resium-viewer`}
              className={!!!clockProps?'no-timeline':''}
              ref={e => {
                if(e && e.cesiumElement) {
                  viewer = e.cesiumElement;
                  clock = viewer?.clock;
                }
              }}
              fullscreenElement={"main-content"}
              full={true}
              fullscreenButton={false}
              baseLayerPicker={false}
              projectionPicker={false}
              navigationHelpButton={false}
              selectionIndicator={false}
              timeline={true}
              scene3DOnly={false}
              skyBox={false}
              skyAtmosphere={false}
              sceneMode={SceneMode.SCENE2D}
              sceneModePicker={false}
              homeButton={false}
              animation={true}
              imageryProvider={baseLayerProvider}
              creditContainer={"main-header"}
              allowDataSourcesToSuspendAnimation={false}
            >
              <Globe
                onTileLoadProgress={tileLoadHandler}
                tileCacheSize={150}
                cartographicLimitRectangle={cartographicLimitRectangle}
              />

              <ScreenSpaceCameraController
                  minimumZoomDistance={configuration?.limits?.minimumAltitude}
                  maximumZoomDistance={configuration?.limits?.maximumAltitude}
              />
              <Clock
                startTime={now}
                currentTime={now}
                stopTime={now}
                multiplier={1.0}
                shouldAnimate={false}
                {...clockProps}
                clockRange={ClockRange.LOOP_STOP}
              />

              <CustomImageryLayer
                definition={selectedDataLayerDefinition}
              />

              <CustomImageryLayer
                  definition={selectedOverlayLayerDefinition}
              />

              <HomeAnimation
                animating={animating}
                longitude={longitude}
                latitude={latitude}
                stop={stopHomeAnimation}
              />
            </Viewer>
          ):<Spinner
              id="globe-spinner"
              message={t("default:loading_content_message")}
            />
        }
      </IonContent>
    </IonPage>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobeWidget)
