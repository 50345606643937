import availableLayers from './Layers.json';

import merge from 'lodash.merge';
import { Resource, DefaultProxy } from "cesium";
import configuration from "../config/config";
type obj = Record<string, any >

interface  LayerDef  {
  type: string,
  source: string,
  layerOptions?: obj,
  providerOptions?:obj
}

const getLayerResource = (source: string, proxy:boolean=false) => {
  const p = proxy?new DefaultProxy(configuration.proxyUrl):undefined;
  return new Resource( {"url":source, "proxy":p} );
}

const constructURL = (defaults:obj, layer:obj) => {
  const isWMTS = layer.type === "WMTS";
  const url = isWMTS?defaults.baseurl.replace(/\{Layer\}/g, layer.providerOptions.layer):defaults.providerOptions.url;
  return getLayerResource(url, defaults.proxy);
}

const getDefinitions = (layersObj:obj, sources:obj)  => {
  const entries:[string, obj][] = Object.entries(layersObj);
  return entries.reduce((o:obj, [k,layer]) => {
    const { type, source, providerOptions, layerOptions } = layer;
    const sourceDefaults = merge({}, sources[type][source], sources[type].defaults);


    sourceDefaults.providerOptions.url = constructURL(sourceDefaults, layer);

    o[k] = {
      providerOptions: merge({}, sourceDefaults.providerOptions, providerOptions),
      layerOptions: layerOptions || {},
      type: type,
      source: source
    }
    return o;
  }, {});
}

const DataLayerDefinitions = (sources:obj) => {
  return getDefinitions(availableLayers.dataLayers, sources);
}

const BaseLayerDefinitions = (sources:obj) => {
  return getDefinitions(availableLayers.baseLayers, sources);
}

const OverlayLayerDefinitions = (sources:obj) => {
  return getDefinitions(availableLayers.overlayLayers, sources);
}

const _baseLayers:[string, LayerDef][] = Object.entries(availableLayers.baseLayers);
const _dataLayers:[string, LayerDef][] = Object.entries(availableLayers.dataLayers);
const _overlayLayers:[string, LayerDef][] = Object.entries(availableLayers.overlayLayers);
const DataLayers = _dataLayers
.map(([k,v]) => ({
  name:k,
  id: v?.providerOptions?.[v.type==="WMTS"?"layer":"layers"]
}))
.filter(layer => !configuration.options.dataLayers || configuration.options.dataLayers.includes(layer.name));

const BaseLayers = _baseLayers
.map(([k,v]) => ({
  name:k,
  id: v?.providerOptions?.[v.type==="WMTS"?"layer":"layers"]
}))
.filter(layer => !configuration.options.baseLayers || configuration.options.baseLayers.includes(layer.name));

const OverlayLayers = _overlayLayers
    .map(([k,v]) => ({
      name:k,
      id: v?.providerOptions?.[v.type==="WMTS"?"layer":"layers"]
    }))
    .filter(layer => !configuration.options.overlayLayers || configuration.options.overlayLayers.includes(layer.name));

export { DataLayers, BaseLayers, OverlayLayers, DataLayerDefinitions, BaseLayerDefinitions, OverlayLayerDefinitions };
