import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonThumbnail,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
  IonProgressBar,
} from '@ionic/react';
import { layersOutline, languageOutline, homeOutline, informationOutline } from 'ionicons/icons';
import LayerSelector from "../components/LayerSelector";
import LanguageSelector from "../components/LanguageSelector";
import { useTranslation } from 'react-i18next';
import LayerDescription from "./LayerDescription";

const MainHeader: React.FC<{id:string, title:string, baseLayer: string, dataLayer: string, overlayLayer: string, setBaseLayer:any, setDataLayer:any, setOverlayLayer:any, goHome:any, loading:boolean}>
    = ({ id, title, baseLayer, dataLayer, overlayLayer, setBaseLayer, setDataLayer, setOverlayLayer, goHome, loading }) => {
  const { t } = useTranslation();
  const homeButtonClickHandler = (e: any) => {
    e.persist();
    goHome();
  }

  return (
    <React.Fragment>
      <IonHeader
        id={id}
      >
        <IonToolbar
          className="main-toolbar"
        >
          <IonButtons slot="start">
            <a target="_blank" rel="noopener noreferrer" href={"https://www.zerogravity.fi/"}>
              <IonThumbnail slot="start" className="header-logo">
                  <img src={`/assets/icon/logo.png`} />
              </IonThumbnail>
            </a>
            <LayerSelector
              icon={layersOutline}
              baseLayer={baseLayer}
              dataLayer={dataLayer}
              overlayLayer={overlayLayer}
              setBaseLayer={setBaseLayer}
              setDataLayer={setDataLayer}
              setOverlayLayer={setOverlayLayer}
            />
            <IonLabel
              className="ion-text-capitalize ion-text-center"
            >
              {t(`default:layers.${dataLayer||'no_selection'}.label`)}
            </IonLabel>
            <LayerDescription
                icon={informationOutline}
                dataLayer={dataLayer}
            />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              title={t(`default:home`)}
              onClick={homeButtonClickHandler}
            >
              <IonIcon slot="icon-only" icon={homeOutline} />
            </IonButton>
            <LanguageSelector
              icon={languageOutline}
            />
          </IonButtons>

        </IonToolbar>
      </IonHeader>
      <IonProgressBar
        id="tile-loader-progress-bar"
        color="secondary"
        class={`loader-bar ${loading?'visible':''}`}
        type="indeterminate"
      />
    </React.Fragment>
  )
}

export default MainHeader;
