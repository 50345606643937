import convert from 'xml-js';
import { pattern } from 'iso8601-duration';
import configuration from "../config/config";
const ISO8601DateTimeRegExpStr = "\\d{4}(-\\d{2}){0,2}(T\\d{2}:\\d{2}:\\d{2}(.\\d{3})?Z)?"
const RangeRegExpStr = `(${ISO8601DateTimeRegExpStr}\\/){2}${pattern.toString().slice(1,-1)}`;
const RangeRegExp = new RegExp(RangeRegExpStr);

type obj = Record<string,any>;

/**
 * a recursive helper function (@see getLayers)
 *
 * @param {object} parentLayer - parent layer object
 */

const getRecursiveLayers = (parentLayer:obj):obj[] => {
  if(parentLayer && Array.isArray(parentLayer)) {
    return parentLayer.reduce((l, o) => [...l, ...(o.Layer?getRecursiveLayers(o.Layer):[o])]
    , []);
  } else {
    return parentLayer.Layer ? getRecursiveLayers(parentLayer.Layer) : [parentLayer]
  }
}

/**
 * returns  all the WMS server's available layers as an array of objects.
 *
 * @param {object} capabilitiesObject - capabilities object
 */


const getWMSLayers = (capabilitiesObject:obj) => {
  return (capabilitiesObject?.Layer &&
  getRecursiveLayers( capabilitiesObject.Layer.Layer).reduce((o:obj, l) => {
    o[l.Name?._text] = {
      dimension: l.Dimension?._text?.trim().split(",").filter((d:string) => d.match(RangeRegExp)),
      units: l.Dimension?._attributes?.units,
      default: l.Dimension?._attributes?.default
    }

    return o;
  }, {}))
  || null;
}

/**
 * returns  all the WMTS server's available layers as an array of objects.
 *
 * @param {object} capabilitiesObject - capabilities object
 */


const getWMTSLayers = (capabilitiesObject:obj) => {
  return capabilitiesObject?.Contents?.Layer?.reduce((o:obj, l:obj) => {
    const dimIsArray = Array.isArray(l.Dimension?.Value);
    o[l?.['ows:Identifier']?._text] = {
      dimension: dimIsArray?l.Dimension.Value.map((v:obj) => v?._text):l.Dimension?.Value?._text?.split(","),
      units: l.Dimension?.['ows:UOM']?._text,
      default: l.Dimension?.Default?._text
    }
    return o;
  }, {})
  || null;
}

/*
 * Recursive helper function that formats the info into an object
 */
const recursive = (i:number,  l:any[], s:obj, o:obj):Promise<obj> => {
  if(i < l[0].length) {
    const type = l[0][i];
    const promises = l[i+1];
    return Promise.all(promises)
    .then(results => {
      o[type] = results.reduce<obj>((sO, r, indx) => {
        sO[s[type][indx]] = r;
        return sO;
      }, {});
      return recursive(i+1, l, s, o);
    })
  } else {
    return Promise.resolve(o);
  }
}

/*
 * Asynchronously loads layer source data from static json files
 * and capabilities file from web for each source
 */
const getSourcesFromWeb = ():Promise<obj> => {
  const sources = {
    "WMS": ["zerogravity", "nasa", "eox", "ecmwf", "europa", "meteofrance-eur-sst-l4", "dataset-oc-eur-chl-multi-l3-chl", "syke", "defaults"],
    "WMTS": ["esa", "earthdata", "terramonitor", "usgs", "defaults"]
  };

  const temp = Object.entries(sources)
  .reduce<any[]>((l,[type, list], i) => {
    l[0].push(type);
    l[i + 1] = list.map(source => {
      return fetch(`/sources/${type}/${source}.json`)
      .then(r => r.json())
      .then(s => {
        const url = s.capabilities;
        if(!!url) {
          const proxyUrl = s.proxy
              ? configuration.proxyUrl + (url as string).replace('?', '&')
              : url;
          return fetch(proxyUrl)
          .then(r => r.text())
          .then(xml => convert.xml2js(xml, {compact:true}))
          .then((json:obj) => {
            if(json.WMS_Capabilities) {
              s.capabilitiesJSON = json.WMS_Capabilities?.Capability;
              s.layers = getWMSLayers(json.WMS_Capabilities?.Capability);
            } else if(json.WMT_MS_Capabilities) {
              s.capabilitiesJSON = json.WMT_MS_Capabilities?.Capability;
              s.layers = getWMSLayers(json.WMT_MS_Capabilities?.Capability);
            } else if(json.Capabilities) {
              s.capabilitiesJSON = json.Capabilities;
              s.layers = getWMTSLayers(json.Capabilities);
            }
            return s;
          })
          .catch(e => {
            console.warn(`failed to fetch capabilities for ${source}`);
            console.trace(e);
            return s;
          })
        } else {
          return s;
        }
      })
    });
    return l;
  }, [[]])

  return recursive(0, temp, sources, {});
}

/*
 * retrieves sources data from localStorage. It fails if data not present or if data
 * is older than 24hrs
 */
const getSourcesFromLocalStorage = ():Promise<obj> => {
  return new Promise((resolve, reject) => {
    const sourcesStr = localStorage.getItem("sources");
    if(sourcesStr) {
      const sourcesObj = JSON.parse(sourcesStr);
      const { sources, timestamp } = sourcesObj;
      const now = new Date();
      const ts = new Date(timestamp);
      const offset = 60*60*24*1000; //24hrs

      if(sources && ts.getTime() + offset > now.getTime()) {
        resolve(sources);
      } else {
        reject(new Error("sources are too old"));
      }
    } else {
      reject(new Error("no sources in localstorage"));
    }
  });
}

/*
 * Tries to retrieve sources data first from localStorage and if not available
 * or stale it tries to get them from the web.
 */
const getSources = ():Promise<obj> => {
  return getSourcesFromLocalStorage()
  .catch(e => {
    console.trace(e);
    return getSourcesFromWeb();
  })
  .then(sources => {
    const now = new Date();
    const sourcesObj = {
      sources: sources,
      timestamp: now
    }
    const sourcesStr = JSON.stringify(sourcesObj);
    try {
      //save to localStorage
      localStorage.setItem("sources", sourcesStr);
    } catch (e) {
      console.log("Local Storage is full, Please empty data");
    }
    return sources
  })
}

export default getSources;
